import React, { useEffect, useState, useRef} from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, NativeSelect, createTheme, ThemeProvider,DialogTitle, Dialog, DialogContent, DialogActions, DialogContentText, IconButton, Typography, Box, Slider, Input, Link } from '@mui/material';
import { Container } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";
import logo from "./assets/logoComparta.JPG";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import NumberFormat from 'react-number-format';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';


/*Validação dos Schame de input do formulario  "^((\\+\\d{2}\\s)?\\(\\d{2}\\)\\s?\\d{4}\\d?\\-\\d{4})?$"*/
const createUserFormSchema = yup.object().shape({
    nome: yup.string().matches("[A-Za-z]+", 'Por favor, insira um nome válido').max(40).required("Por favor, O nome precisa ser preenchido"),
    sobrenome: yup.string().min(1, 'Por favor, o sobrenome deve ter no minimo 6 caracteres').max(40).required("Por favor, O sobrenome precisa ser Preenchido"),
    email:yup.string().email('POr favor, Insira um e-mail válido').required("Por favor, Insira um e-mail válido"),
    telefone:yup.number().typeError('Por favor, Insira um telefone válido').required("Por favor, Insira um telefone válido"),
    /*telefone:yup.number("Precisa Inserir um número de Telefone").min(11, 'Precisa inserir no minimo 6 caracteres'),*/
});


const theme = createTheme({
   palette: {
      palette: {
        primary: {
          main: '#008e76',
        },
      },
  },
});




export default function Form() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ resolver: yupResolver(createUserFormSchema),
  });
  
  console.log(errors)
  /*const onSubmit = data => console.log(data);*/
  const onSubmit = async(data) => {

    console.log(Concessionaria)

    const result = await fetch(`https://api.xcogni.com/simulacao?email=${data.email}&telefone=${data.telefone}&nome=${data.nome}&sobrenome=${data.sobrenome}&Concessionaria=${Concessionaria}&TipoInstalacao=${Instalacao}&ValorConta=${valorConta}&moradia=${Moradia}&Classe="Classe B1 - Residencial"&bandeira=${Bandeira}`, {
        mode:'cors',
        headers: {'x-api-key':'HHgpbvpUwra4sfdglZI6M47FMU6B45oX2YhHoK1Z'}
    }).then((Response) => Response.json())
      .then((jsonData) =>{
        setTableData(jsonData);
      });  

  

    setOpenPopup(true);
  
  };
  
  const [Concessionaria, setConcessionaria] = React.useState('');
  const [Instalacao, setInstalacao] = React.useState('');
  const [Bandeira, setBandeira] = React.useState('');
  const [Moradia, setMoradia] = React.useState('');
  const [Classe, setClasse] = React.useState('');
  const [openPopup, setOpenPopup] = React.useState(false);
  const [tableData, setTableData] = React.useState({});
  const [valorConta, setValorConta] = React.useState(400);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleBackurl = () => {
    window.location.href = "https://www.comparta.com.br";
  };

  const handleConcessionaria = (event) => {
    setConcessionaria(event.target.value);
  };

  const handleInstalacao = (event) => {
    setInstalacao(event.target.value);
  };

  const handleBandeira = (event) => {
    setBandeira(event.target.value);
  };

  const handleMoradia = (event) => {
    setMoradia(event.target.value);
  };


  const handleClasse = (event) => {
    setClasse(event.target.value);
  };

  const handleSliderChange = (event, newValue) => {
    setValorConta(newValue);
  };

  const handleInputChange = (event) => {
    setValorConta(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (valorConta < 0) {
      setValorConta(0);
    } else if (valorConta > 5000) {
      setValorConta(5000);
    }
  };




    return (
    <ThemeProvider theme={theme}>
      <Container className='container' maxWidth="md" >
          <form className ='form_content' autoComplete='on' >
              <Grid container justifyContent="center" spacing ={3}>
                    <Grid item xs={10} sm={9} md={9} ></Grid>
                    <Grid item xs={10} sm={9} md={9}  >
                      <img src={logo} className="logo" alt=''/></Grid>
                    <Grid item xs={2} sm={1} md={1} >
                        
                        <IconButton onClick={handleBackurl}>
                            <ArrowBackTwoToneIcon fontSize="large" style = {{color:"#40997E"}}></ArrowBackTwoToneIcon>                 
                            <Typography variant = 'body1' style={{color:"#40997E"}}>Voltar</Typography>
                        </IconButton>
                        
                    </Grid>
                    <Grid  item xs={12} sm={6} md={6} >
                      <TextField label="Nome"  fullWidth="true" id="name"  name = "nome" type='name' required {...register("nome")}   ></TextField>
                      {errors.nome && <span className='errorMessage'>{errors.nome.message}</span>}
                    </Grid>
                    <Grid  item xs={12} sm={6} md={6}  >
                      <TextField label="Sobrenome"  fullWidth="true" id="sobrenome"  name = "sobrenome" type ='name' required {...register("sobrenome")}   ></TextField>
                      {errors.sobrenome && <span className='errorMessage'>{errors.sobrenome.message}</span>}
                    </Grid>
                    <Grid  item xs={12} sm={6} md={6}  >
                      <TextField 
                          label="E-mail"
                          fullWidth="true"
                          id="email"
                          name = "email"
                          type = 'email'
                          required 
                          placeholder="exemplo@dominio.com"
                          {...register("email")}></TextField>
                      {errors.email && <span className='errorMessage'>{errors.email.message}</span>} 
                    </Grid>
                    <Grid  item xs={12} sm={6} md={6}>
                      <TextField 
                          label='Telefone' 
                          name ='Telefone' 
                          fullWidth="true" 
                          required 
                          type='phone'
                          placeholder = "119XXXXXXXX"
                          {...register("telefone")}>
                        </TextField>
                        {errors.telefone && <span className='errorMessage'>{errors.telefone.message}</span>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl variant="standard"  sx={{ paddingTop:1 , width: "100%", height:"100%"}} required  >
                          <InputLabel id="select-moradia-label">Moradia</InputLabel>
                          <Select 
                            defaultValue = "Casa"
                            labelId="select-moradia" 
                            id="select-moradia"
                            value={Moradia}
                            onChange={handleMoradia}
                            label="moradia">
                                <MenuItem value={"Apartamento"} >Apartamento</MenuItem>
                                <MenuItem value={"Casa/Sobrado"} >Casa/Sobrado</MenuItem>
                                <MenuItem value={"Sitio/Fazenda"} >Sitio/Fazenda</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl variant="standard"  sx={{ paddingTop:1 , width: "100%", height:"100%"}} required  >
                          <InputLabel id="dselect-concessionaria-label">Concessionaria</InputLabel>
                          <Select 
                            defaultValue = "EDP"
                            labelId="select-concessionaria" 
                            id="select-concessionaria"
                            value={Concessionaria}
                            onChange={handleConcessionaria}
                            label="concessionaria">
                                <MenuItem value={"CPFL PAULISTA"} >CPFL Paulista -SP</MenuItem>
                                <MenuItem value={"CPFL PIRATININGA"} >CPFL Piratiniga - SP</MenuItem>
                                <MenuItem value={"CPFL SANTA CRUZ"} >CPFL Santa Cruz - SP</MenuItem>
                                <MenuItem value={"CEMIG"}>Cemig - MG</MenuItem>
                                <MenuItem value={"EDP"} >EDP - SP</MenuItem>
                                <MenuItem value={"LIGHT"} >Light - RJ</MenuItem>
                                <MenuItem value={"ENEL SP"} >Enel - SP</MenuItem>
                                <MenuItem value={"COELBA"} >Coelba - BA</MenuItem>
                                <MenuItem value={"ELEKTRO"} >Elektro - SP</MenuItem>
                                <MenuItem value={"ENEL RJ"} >Enel - RJ</MenuItem>
                                <MenuItem value={"EMT"} >Enegersia Mato Grosso - MT</MenuItem>
                                <MenuItem value={"EMS"} >Enegersia Mato Grosso do Sul - MS</MenuItem>
                                <MenuItem value={"ESS"} >Enegersia Sul Sudeste - SP</MenuItem>
                                <MenuItem value={"EMG"} >Enegersia Minas Gerais - MG</MenuItem>
                                <MenuItem value={"AME"} >Amazonas Energia - AM</MenuItem>
                                <MenuItem value={"CEMAR"} >Equatorial Maranhão - MA</MenuItem>
                                <MenuItem value={"COSERN"} >CIA Energetica do Rio Grande Norte - RN</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl variant="standard"  sx={{ paddingTop:1 , width: "100%", height:"100%"}} required  >
                          <InputLabel id="dselect-instalacao-label">Tipo instalacao</InputLabel>
                          <Select 
                            labelId="select-instalacao" 
                            id="select-instalacao"
                            value={Instalacao}
                            onChange={handleInstalacao}
                            label="concessionaria">
                                <MenuItem value={"MONOFASICO"}  >Monofásico</MenuItem>
                                <MenuItem value={"BIFASICO"}    >Bifásico</MenuItem>
                                <MenuItem value={"TRIFASICO"}   >Trifásico</MenuItem>
                                <MenuItem value={"** MONOFASICO"}           >Não Sei</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl variant="standard"  sx={{ paddingTop:1 , width: "100%", height:"100%"}} required  >
                          <InputLabel id="dselect-bandeira-label">Bandeira Tarifária</InputLabel>
                          <Select 
                            labelId="select-bandeira" 
                            id="select-bandeira"
                            value={Bandeira}
                            onChange={handleBandeira}
                            label="bandeira">
                                <MenuItem value={"VERDE"}             >Verde</MenuItem>
                                <MenuItem value={"AMARELA"}           >Amarela</MenuItem>
                                <MenuItem value={"VERMELHA 1"}         >Vermelha 1</MenuItem>
                                <MenuItem value={"VERMELHA 2"}         >Vermelha 2</MenuItem>
                                <MenuItem value={"ESCASSEZ HIDRICA"}   >Escassez Hidríca</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl variant="standard"  sx={{ paddingTop:1 , width: "100%", height:"100%"}} required>
                          <InputLabel id="dselect-classe-label">Classe Tarifária</InputLabel>
                          <Select 
                            defaultValue={"B1 - RESIDENCIAL"}
                            labelId="select-classe" 
                            id="select-classe"
                            value={Classe}
                            onChange={handleClasse}
                            label="classe">
                                <MenuItem value={"B1 RESIDENCIAL"}             >B1 Residencial</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                      <Box sx={{ width: '100%' }}>
                          <Typography id="input-slider" Variant = 'subtitle1' style ={{color:'grey'}} gutterBottom>
                            Valor da Fatura (R$)
                          </Typography>
                          <Grid container spacing={2} alignItems="center">
                            
                            <Grid item xs={8} sm={9} md={9}>
                              <Slider
                                value={typeof valorConta === 'number' ? valorConta : 0}
                                onChange={handleSliderChange}
                                aria-labelledby="input-slider"
                                min ={200}
                                max ={5000}
                                valueLabelDisplay='auto'
                                style={{'color':'#40997E'}}
                                required
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <Input
                                value={valorConta}
                                size="large"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                valueLabelDisplay ='auto'
                                inputProps={{
                                  step: 100,
                                  min: 200,
                                  max: 5000,
                                  type: 'number',
                                  'aria-labelledby': 'input-slider',
                                }}
                              />
                            </Grid>
                          </Grid>
                      </Box>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={12} >
                      <Button  
                          color="primary"
                          className='btnSubmit'
                          onClick = {handleSubmit(onSubmit)}
                          type="submit" 
                          variant="contained" 
                          justifyContent ='center' 
                          size ="large"
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#21b6ae",
                            padding: "10px 10px",
                            fontSize: "16px",
                            width: '100%',
                        }}>
                        Simular
                        </Button>
                        <Dialog
                            open={openPopup}
                            onClose={handleClosePopup}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <Grid container justifyContent= 'center' spancing={1}>
                                <Grid item xs={11} sm={11} md={11}>
                                    <DialogTitle id="alert-dialog-title">
                                      <Typography variant ='h4' justifyContent='center' >
                                          Resultado da Simulação
                                      </Typography>
                                    </DialogTitle>
                                   
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <DialogActions>
                                      <IconButton onClick={handleClosePopup}>
                                          <CloseIcon></CloseIcon>
                                      </IconButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'center'} spacing={2}>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                      <Divider>
                                        <Chip label ='Considerações' style={{marginBottom: '10px'}}></Chip>
                                      </Divider>
                                      <Typography variant ='subtitle2'>
                                          1 - Os valores apresentados na simulação podem sofrer alteração, sem aviso prévio, considerando: (i) Mudanças na legislação vigente para setor elétrico; (ii) Mudanças na tarifa da distribuidora local que atende o cliente; (iii) Análise do perfil do cliente apresentado no cadastro. 
                                      </Typography>
                                      <Typography variant ='subtitle2'>
                                          2 - Os planos de geração compartilhada de energia elétrica com descontos não têm custo adicional de adesão, e nem custo adicional de manutenção mensal, para os clientes consumidores.
                                      </Typography>
                                      <Typography variant ='subtitle2'>
                                          3 - Para manifestar interesse na adesão aos planos de descontos, preencher corretamente o formulário do link abaixo: 
                                      </Typography>
                                      <Link href='https://comparta.com.br/' variant='body2'>Comparta Energia</Link>
                                      <Typography variant ='subtitle2'>
                                          4 - A adesão a um plano de desconto está sujeita a disponibilidade de quotas de energia na área da distribuidora onde se localiza a unidade de consumo do interessado. E isto será informado ao interessado após a análise Do formulário de manifestação de interesse.
                                      </Typography>
                                      <Divider>
                                        <Chip label ='Dados de Entrada' style={{margin: '15px'}}></Chip>
                                      </Divider>
                                      <Box>
                                          <Typography variant="h10">Concessionaria: </Typography>
                                          <Typography variant='h10'> {Concessionaria}   </Typography>
                                      </Box>
                                      <Box>
                                          <Typography variant="h10">Tipo Instalação: </Typography>
                                          <Typography variant='h10'> {Instalacao}   </Typography>
                                      </Box>
                                      <Box>
                                          <Typography variant="h10">Classe Tarifária: </Typography>
                                          <Typography variant='h10'> {Classe}   </Typography>
                                      </Box>
                                      <Box>
                                          <Typography variant="h10">Bandeira Tarifária: </Typography>
                                          <Typography variant='h10'> {Bandeira}   </Typography>
                                      </Box>
                                      <Divider>
                                        <Chip label ='Antes da Compensação' style={{margin: '15px'}}></Chip>
                                      </Divider>  
                                      <Box>
                                          <Typography variant="h10">Valor Total: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={valorConta} decimalScale={2} displayType={'text'} prefix={'R$'}></NumberFormat>
                                          </Typography>
                                      </Box>
                                      <Box>
                                          <Typography variant="h10">Energia Estimada: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.energia_consumida} decimalScale={0} displayType={'text'} suffix={'kWh'}></NumberFormat>
                                          </Typography>
                                      </Box>
                                      <Divider>
                                        <Chip label ='Depois da Compensação' style={{margin: '15px'}}></Chip>
                                      </Divider>      
                                      <Box>
                                          <Typography variant="h10">Valor Total Estimado: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.valor_total}  decimalScale ={2} displayType={'text'} prefix={'R$'}></NumberFormat>
                                          </Typography>
                                      </Box>   
                                      <Box>
                                          <Typography variant="h10">Cota de Energia Estimada: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.cota_energia}  decimalScale ={0} displayType={'text'} suffix={'kWh'}></NumberFormat>
                                          </Typography>
                                      </Box>   
                                      <Box>
                                          <Typography variant="h10">Desconto Estimado: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.desconto_final*100}  decimalScale ={2} displayType={'text'} suffix={'%'}></NumberFormat>
                                          </Typography>
                                      </Box>  
                                      <Box>
                                          <Typography variant="h10">Economia Mensal Estimada: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.economia_mensal}  decimalScale ={2} displayType={'text'} prefix={'R$'}></NumberFormat>
                                          </Typography>
                                      </Box>   
                                      <Box>
                                          <Typography variant="h10">Economia Anual Estimada: </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.economia_anual}  decimalScale ={2} displayType={'text'} prefix={'R$'}></NumberFormat>
                                          </Typography>
                                      </Box>  
                                      <Box>
                                          <Typography variant="h10">Redução Anual de emissão Estimada (Kg.CO2): </Typography>
                                          <Typography variant='h10'> 
                                            <NumberFormat value={tableData.emissao_co2_anual}  decimalScale ={2} displayType={'text'}></NumberFormat>
                                          </Typography>
                                      </Box>                                       
                                  </DialogContentText>
                                </DialogContent>
                            </Grid>
                          </Dialog>
                    </Grid>
              </Grid>
          </form>
      </Container>
    </ThemeProvider>
    );
}